import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./common/theme/index.css";
Vue.use(ElementUI);

import API from "./api/api";
Vue.prototype.$http = API;
Vue.prototype.baseURI = "http://192.168.124.13:8300";
Vue.prototype.axios = axios;

import Util from "./common/utils.js";
Vue.prototype.$u = Util;

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
