import {
	get,
	post
} from './request'

const API = {
	// getAreaByPid
	getAreaByPid(data) {
		return get('/api/web/common/getAreaByPid', data)
	},
	// allDictType
	allDictType(data) {
		return get('/api/web/common/allDictType', data)
	},
	// webDict
	getByDictType(data) {
		return get('/api/web/common/getByDictType', data)
	},
	// submitStep1
	submitStep1(data) {
		return post('/api/web/common/submit/step1', data)
	},
	// submitStep2
	submitStep2(data) {
		return post('/api/web/common/submit/step2', data)
	},
	// uploadFile
	uploadFile(data) {
		return post('/api/web/common/upload/file', data)
	},
	// sendCode
	sendCode(data) {
		return get('/api/web/common/sendCode', data)
	},
	// validateMessage
	validateMessage(data) {
		return post('/api/web/common/sendCode/validateMessage', data)
	},
    getDataByEmail(data) {
        return get('/api/web/common/getDataByEmail', data)
    }


}

export default API
