<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./common/index.css";

#app {
  /* font-family: PingFang SC; */
  font-family: "Arial";
  color: #ffffff;
  height: 100%;
}

a {
  color: #ffffff;
  text-decoration: none;
}

html,
body {
  /* font-family: "Microsoft YaHei"; */
  font-family: "Arial";
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
}

div {
  box-sizing: border-box;
}

input {
  border: none;
  background: transparent;
  outline: none;
}

input:disabled {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.el-input__prefix {
  /* right: 10px !important; */
  /* text-align: right !important; */
}

.el-input--prefix .el-input__inner::placeholder {
  padding-left: 2px !important;
}
.el-input--prefix .el-input__inner {
  padding-left: 30px !important;
}

.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  color: #606266 !important;
}

.el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff !important;
  color: #606266 !important;
  font-family: "Microsoft YaHei";
}

.el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #ffffff !important;
}

.el-checkbox__inner::after {
  border-color: #ffffff !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #2ec9c3 !important;
  border-color: #2ec9c3 !important;
}

.el-checkbox__input.is-disabled.is-checked + span.el-checkbox__label {
  color: #2ec9c3 !important;
}

.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #2ec9c3 !important;
  border-color: #2ec9c3 !important;
}

.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #ffffff !important;
}
</style>
