/** 验证电子邮箱格式 */
function email(value) {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
        value
    );
}

/** 验证手机格式   /^[1][3,4,5,6,7,8,9][0-9]{9}$/ */
function mobile(value) {
    return /^1[23456789]\d{9}$/.test(value);
}

/**
 * 节流
 * @param {*} fn
 * @param {number} [delay=1000]
 * @return {*}
 */
function debounce(fn, delay = 1000) {
    let timer = null;
    return function (...args) {
        console.log(timer);
        console.log(Date.now());
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(null, args);
            console.log(Date.now());
        }, delay);
    };
}

/**
 * 过滤数据中的ID
 * @param {*} data
 * @return {*}
 */
function filterId(data, fields) {
    const toString = Object.prototype.toString;
    const isArray = Array.isArray(data);
    if (!isArray) {
        data = [data];
    }
    if (!Array.isArray(fields)) {
        fields = [fields];
    }
    const dataList = data.map((items) => {
        const obj = {};
        Object.keys(items).forEach((key) => {
            if (fields.includes(key)) {
                obj[key] = items[key];
                return;
            }
            if (toString.call(items[key]) == "[object Object]") {
                if (Reflect.has(items[key], "id")) {
                    Reflect.deleteProperty(items[key], "id");
                }
            } else if (toString.call(items[key]) == "[object Array]") {
                items[key] = items[key].map((item) => {
                    if (toString.call(item) == "[object Object]") {
                        if (Reflect.has(item, "id")) {
                            Reflect.deleteProperty(item, "id");
                        }
                    }
                    return item;
                });
            }
            // else if (key == 'id') {
            //     return
            // }
            obj[key] = items[key];
        });
        return obj;
    });
    if (isArray) {
        return dataList;
    }
    return dataList[0];
}

export default {
    email,
    mobile,
    debounce,
    filterId,
};
