<template>
    <div class="container">
        <div class="back" style="height: 50%">
            <img src="../assets/back.png" />
        </div>
        <div class="surity">
            <img src="../assets/surity.png" />
        </div>
        <div class="names">Vendor Repository</div>
        <div class="InputBox">
            <div
                class="inputs flex-center"
                style="width: 414px; margin-bottom: 31px"
            >
                <img src="../assets/u.png" />
                <input
                    type="text"
                    v-model="form.email"
                    placeholder="Email Address"
                />
            </div>
            <div class="flex-center">
                <div class="inputs flex-center">
                    <img src="../assets/p.png" />
                    <input
                        type="text"
                        v-model="form.code"
                        placeholder="Verification code"
                    />
                </div>
                <div
                    class="code"
                    :class="{ 'code-disable': disable }"
                    @click="Verification"
                >
                    {{ codeText }}
                </div>
            </div>
            <div class="confirm" @click="login">Confirm</div>
            <div class="info" @click="$router.push('/Vendor')">
                For first visit, please provide your basic information here.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            form: {
                email: "",
                code: "",
            },
            codeText: "Verification code",
            timer: null,
            disable: false,
        };
    },
    created() {
        localStorage.clear();
        this.ownership_structure();
        this.product_scope();
        this.key_market_customers();
        this.in_house_processes();
        this.sex();
        this.quality_management_systems();
        this.laboratory();
        this.export_port();
        this.design_capability();
        this.capacity_level();
        this.business_type();
        this.accreditation();
        this.yn_dict();
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        login() {
            if (!this.form.email) {
                return this.$message({
                    message: "Please Input Email Address",
                    type: "error",
                });
            }
            if (!this.form.code) {
                return this.$message({
                    message: "Please Input Verification code",
                    type: "error",
                });
            }

            this.$http.validateMessage(this.form).then((res) => {
                if (res.code == 200) {
                    let data = JSON.stringify(res.data);

                    localStorage.setItem("dataList", data);
                    setTimeout(() => {
                        this.$router.push("/Index");
                    }, 500);
                }
            });
        },

        Verification() {
            if (!this.form.email) {
                return this.$message({
                    message: "Please Input Email Address",
                    type: "error",
                });
            }
            if (this.disable) return;
            this.disable = true;
            this.codeText = 60;
            this.timer = setInterval(() => {
                this.codeText--;
                if (this.codeText < 1) {
                    this.codeText = "Verification code";
                    this.disable = false;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.$http
                .sendCode({
                    email: this.form.email,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            message: "Sent successfully",
                            type: "success",
                        });
                    }
                })
                .catch(() => {
                    this.disable = false;
                    clearInterval(this.timer);
                });
        },

        ownership_structure() {
            this.$http
                .getByDictType({
                    dictType: "ownership_structure",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("ownership_structure", data);
                });
        },
        product_scope() {
            this.$http
                .getByDictType({
                    dictType: "product_scope",
                })
                .then((res) => {
                    let data = res.data.filter((o) => o.childArray.length > 0);
                    let data1 = JSON.stringify(data);
                    localStorage.setItem("product_scope", data1);
                });
        },
        key_market_customers() {
            this.$http
                .getByDictType({
                    dictType: "key_market_customers",
                })
                .then((res) => {
                    let data = res.data.filter((o) => o.childArray.length > 0);

                    let data1 = JSON.stringify(data);
                    localStorage.setItem("key_market_customers", data1);
                });
        },
        in_house_processes() {
            this.$http
                .getByDictType({
                    dictType: "in_house_processes",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("in_house_processes", data);
                });
        },
        sex() {
            this.$http
                .getByDictType({
                    dictType: "sex",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("sex", data);
                });
        },
        quality_management_systems() {
            this.$http
                .getByDictType({
                    dictType: "quality_management_systems",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("quality_management_systems", data);
                });
        },
        laboratory() {
            this.$http
                .getByDictType({
                    dictType: "laboratory",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("laboratory", data);
                });
        },
        export_port() {
            this.$http
                .getByDictType({
                    dictType: "export_port",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("export_port", data);
                });
        },
        design_capability() {
            this.$http
                .getByDictType({
                    dictType: "design_capability",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("design_capability", data);
                });
        },
        capacity_level() {
            this.$http
                .getByDictType({
                    dictType: "capacity_level",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("capacity_level", data);
                });
        },
        business_type() {
            this.$http
                .getByDictType({
                    dictType: "business_type",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("business_type", data);
                });
        },
        accreditation() {
            this.$http
                .getByDictType({
                    dictType: "accreditation",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("accreditation", data);
                });
        },
        yn_dict() {
            this.$http
                .getByDictType({
                    dictType: "yn_dict",
                })
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("yn_dict", data);
                });
        },
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #eeeeee;
}

.container .back {
    width: 100%;
    position: absolute;
}

.container .back img {
    height: 100%;
    width: 100%;
}

.surity {
    position: absolute;
    top: 38px;
    left: 112px;
}

.InputBox {
    position: absolute;
    top: calc(50% - 195px);
    left: calc(50% - 254.5px);
    width: 509px;
    height: 390px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 8px rgba(30, 72, 111, 0.35);
    border-radius: 10px;

    padding: 0 45px;
    padding-top: 52px;
}

.InputBox .inputs {
    width: 210px;
    height: 44px;
    background: #ffffff !important;
    opacity: 1 !important;
    border-radius: 8px;
    margin-right: 30px;
    padding-left: 20px;
    box-shadow: 0px 3px 8px 0px rgba(30, 72, 111, 0.35) inset;
}

.InputBox img {
    margin-top: 3px;
    height: 19px;
    width: 17px;
    margin-right: 17px;
}

input {
    border: none;
    height: 40px;
    background: transparent;
    outline: none;
    width: 340px;
}

.code {
    width: 188px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    opacity: 1;
    background: #2ec9c3;
    border-radius: 8px;
    margin: 0 auto;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
}

.code-disable {
    background: rgba(255, 255, 255, 0.5);
}
.confirm {
    width: 414px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    opacity: 1;
    background: #2ec9c3;
    border-radius: 8px;
    margin: 0 auto;
    height: 56px;
    line-height: 56px;
    margin-top: 44px;
    cursor: pointer;
}

.info {
    margin-top: 30px;
    font-size: 20px;
    font-family: Arial;
    font-weight: 400;
    line-height: 27px;
    color: #2ec9c3;
    opacity: 1;
    cursor: pointer;
}
.names {
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
    font-size: 94px;
    font-family: Arial;
    font-weight: 400;
    line-height: 125px;
    color: #2ec9c3;
    opacity: 1;
}
</style>
