import axios from "axios";
import { Message } from "element-ui";

const instance = axios.create({
    // baseURL: "http://192.168.124.23:8300",
    // baseURL: 'http://47.108.238.247:31102',
    // baseURL: "http://192.168.0.118:8300/",
    // baseURL: "http://yanchang-api.ydsz.top",
    baseURL: "https://api.surity.co",
    timeout: 100000,
    headers: {
        "Content-Type": "application/json",
    },
});

// 添加请求拦截器
instance.interceptors.request.use(
    (config) => {
        if (config.url == "/api/web/common/upload/file") {
            config.headers["Content-Type"] = "multipart/form-data";
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 添加响应拦截器
instance.interceptors.response.use(
    (response) => {
        const res = response.data;
        let message = res.message;
        if (res.code == 200) {
            return res;
        }
        Message({
            type: "error",
            message: message,
            duration: 3000,
        });
    },
    (error) => {
        Message({
            type: "error",
            message: "For first visit, please provide your basic information via below link.",
            duration: 3000,
        });
        return Promise.reject(error);
    }
);
export function get(url, params) {
    return instance.get(url, {
        params,
    });
}
export function post(url, data) {
    return instance.post(url, data, {
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            "Content-Type": "application/json",
        },
    });
}

// Content-Type: multipart/form-data;
export function del(url) {
    return instance.delete(url);
}
export function put(url, data) {
    return instance.put(url, data);
}